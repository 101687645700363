import NavigationItem from './navigationItem/navigationItem';

const navigationItems = (props) => (

    <nav className='navigation_nav'>
        <ul className='navigation_list'>
            <NavigationItem link='/' toggleShowNavigation={props.toggleShowNavigation}>HOME</NavigationItem>
            <NavigationItem link='/journey' toggleShowNavigation={props.toggleShowNavigation}>MY JOURNEY</NavigationItem>
            <NavigationItem link='/projects' toggleShowNavigation={props.toggleShowNavigation}>MY WORK</NavigationItem>
            <NavigationItem link='/blog' toggleShowNavigation={props.toggleShowNavigation}>MY BLOG</NavigationItem>
        </ul>
    </nav>
);

export default navigationItems;