import React, { Suspense } from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

import Layout from './layout/layout';
import ScrollToTop from './components/navigation/scrollToTop';

const Index = React.lazy(() => {
    return import('./pages/index');
});
const Journey = React.lazy(() => {
    return import('./pages/journey');
});
const Projects = React.lazy(() => {
    return import('./pages/projects');
});
const Blog = React.lazy(() => {
    return import('./pages/blog');
});
const BlogItem = React.lazy(() => {
    return import("./pages/blogItem");
});

const App = () => {

    let routes = (
        <ScrollToTop>
            <Switch>
                <Route exact path="/" component={Index} />
                <Route path="/journey" component={Journey} />
                <Route path="/projects" component={Projects} />
                <Route path="/blog" component={Blog} />
                <Route path="/blogitem/:id" component={BlogItem} />
                <Redirect to="/" />
            </Switch>
        </ScrollToTop>
    );

    return (
        <div>
            <Layout>
                <Suspense fallback={<p>Loading...</p>}>{ routes }</Suspense>
            </Layout>
        </div>
    );
}

export default withRouter(App);