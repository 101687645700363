import {NavLink} from 'react-router-dom';

const navigationItem = (props) => (

    <li className='navigation_item'>
        <NavLink
            className='navigation_link'
            to={props.link}
            exact
            activeClassName='navigation_item__active'
            onClick={props.toggleShowNavigation}
        >
            {props.children}
        </NavLink>
    </li>
);

export default navigationItem;